import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
  },
  {
    path: '/new-request',
    name: 'newRequest',
    component: () => import('@/views/NewRequest.vue'),
  },
  {
    path: '/poll/:code',
    name: 'poll',
    component: () => import('@/views/PollView.vue'),
  },
  {
    path: '/404',
    name: '404 Not Found',
    component: () => import('@/views/NotFound.vue'),
  },
  {
    path: '*',
    redirect: '/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
