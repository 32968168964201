import axios from 'axios';

export default {
  namespaced: true,
  state: {
    api: {
      components: 'seoprostor:send',
    },
  },
  getters: {
    getApiUrl(state, getters, rootState, rootGetters) {
      const url = rootGetters.getApiUrl;
      url.searchParams.set('c', state.api.components);

      return url.toString();
    },
  },
  actions: {
    sendScore({ getters }, { params }) {
      return new Promise((resolve) => {
        const url = new URL(getters.getApiUrl);
        url.searchParams.set('action', 'sendScore');

        axios
          .post(url.toString(), params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    sendReview({ getters }, { params }) {
      return new Promise((resolve) => {
        const url = new URL(getters.getApiUrl);
        url.searchParams.set('action', 'sendReview');

        axios
          .post(url.toString(), params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    sendNewRrequest({ getters }, { params }) {
      return new Promise((resolve) => {
        const url = new URL(getters.getApiUrl);
        url.searchParams.set('action', 'sendNewRrequest');

        axios
          .post(url.toString(), params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
  },
};
