import Vue from 'vue';
import Inputmask from 'inputmask';
import App from './App.vue';
import router from './router';
import store from './store';

const VueInputMask = {
  install: () => {
    Vue.directive('mask', {
      bind: (el, binding) => {
        Inputmask(binding.value).mask(el);
      },
    });
  },
};

Vue.config.productionTip = false;
Vue.use(VueInputMask);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
