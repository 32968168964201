<template>
  <div id="app" class="app">
    <template v-if="loading">
      <Preloader/>
    </template>

    <template v-else>
      <template v-if="error">
        <h1 class="text-center">Ошибка!</h1>
      </template>

      <template v-else>
        <YandexMetrika
          :counter-id="settingsYandexMetrika"
        />
        <router-view/>
      </template>
    </template>
  </div>
</template>

<script>
import Preloader from '@/components/Preloader.vue';
import YandexMetrika from '@/components/YandexMetrika.vue';

export default {
  name: 'App',
  components: {
    Preloader,
    YandexMetrika,
  },
  data: () => ({
    error: false,
    loading: false,
  }),
  computed: {
    settingsYandexMetrika() {
      return this.$store.getters['settings/getYandexMetrika'];
    },
  },
  methods: {
    getSettings() {
      this.loading = true;

      this.$store.dispatch('settings/getSettings').then((response) => {
        if (!response) {
          this.error = true;
        }

        this.loading = false;
      });
    },
  },
  created() {
    this.getSettings();
  },
};
</script>

<style lang="scss">
  @import '~@/style/typography';
  @import '~@/style/grid';

  .app {
    display: grid;
    padding-top: 15px;

    @media (min-width: 768px) {
      align-items: center;
      min-height: 100vh;
    }
  }
</style>
