import Vue from 'vue';
import Vuex from 'vuex';

import settings from './modules/settings';
import company from './modules/company';
import send from './modules/send';
import page from './modules/page';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    api: {
      path: '/bitrix/services/main/ajax.php',
      mode: 'class',
    },
  },
  getters: {
    getApiUrl(state) {
      const { protocol, hostname, port } = window.location;
      let urlAddress = `${protocol}//${hostname}`;
      if (port.length > 0) {
        urlAddress += `:${port}`;
      }

      const url = new URL(`${urlAddress}${state.api.path}`);
      url.searchParams.set('mode', state.api.mode);

      return url;
    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    settings,
    company,
    send,
    page,
  },
});
