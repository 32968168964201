import axios from 'axios';

export default {
  namespaced: true,
  state: {
    api: {
      components: 'seoprostor:company',
    },
    company: {},
  },
  getters: {
    getApiUrl(state, getters, rootState, rootGetters) {
      const url = rootGetters.getApiUrl;
      url.searchParams.set('c', state.api.components);

      return url.toString();
    },
    getCompany(state) {
      return state.company;
    },
    getCompanyName(state) {
      return state.company.name;
    },
    getCompanyLogo(state) {
      return state.company.logo;
    },
    getCompanyTitle(state) {
      return state.company.title;
    },
    getCompanyText(state) {
      return state.company.text;
    },
    getCompanyBtnText(state) {
      return state.company.btnText;
    },
    getCompanyServices(state) {
      return state.company.services;
    },
    getCompanyServicesTitle(state) {
      return state.company.servicesPage.title;
    },
    getCompanyServicesText(state) {
      return state.company.servicesPage.text;
    },
    getCompanyBranchsText(state) {
      return state.company.branchsPage.text;
    },
    getCompanyYandexMetrika(state) {
      return state.company.yandexMetrika;
    },
    getCompanyForm: (state) => (form) => {
      const selectedForm = state.company[form] || false;
      return selectedForm;
    },
    getCompanyFormTitle: (state, getters) => (form) => {
      const selectedForm = getters.getCompanyForm(form);
      return (selectedForm) ? selectedForm.title : '';
    },
    getCompanyFormText: (state, getters) => (form) => {
      const selectedForm = getters.getCompanyForm(form);
      return (selectedForm) ? selectedForm.text : '';
    },
    getCompanyFormBtnText: (state, getters) => (form) => {
      const selectedForm = getters.getCompanyForm(form);
      return (selectedForm) ? selectedForm.btnText : '';
    },
    getCompanyFormField: (state, getters) => (form, field) => {
      const selectedForm = getters.getCompanyForm(form);
      const selectedFild = selectedForm.fields[field] || false;
      return selectedFild;
    },
    getCompanyFormFieldTitle: (state, getters) => (form, field) => {
      const selectedFild = getters.getCompanyFormField(form, field);
      return (selectedFild) ? selectedFild.title : '';
    },
    getCompanyFormFieldPlaceholder: (state, getters) => (form, field) => {
      const selectedFild = getters.getCompanyFormField(form, field);
      return (selectedFild) ? selectedFild.placeholder : '';
    },
    // getCompanyFeedbackGoodText(state) {
    //   return state.company.feedbackGood.text;
    // },
    // getCompanyFeedbackGoodMessageTitle(state) {
    //   return state.company.feedbackGood.message.title;
    // },
    // getCompanyFeedbackGoodMessageTitle(state) {
    //   return state.company.feedbackGood.message.title;
    // },
  },
  mutations: {
    setCompany(state, { item }) {
      state.company = item;
    },
  },
  actions: {
    getCompany({ getters, commit }, { code }) {
      return new Promise((resolve) => {
        const url = new URL(getters.getApiUrl);
        url.searchParams.set('action', 'getCompanyInfo');
        url.searchParams.set('code', code);

        axios
          .get(url.toString())
          .then((response) => {
            const item = response.data.data;
            commit('setCompany', { item });
            resolve(item);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
  },
};
