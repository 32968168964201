import axios from 'axios';

export default {
  namespaced: true,
  state: {
    api: {
      components: 'seoprostor:settings',
    },
    settings: {},
  },
  getters: {
    getApiUrl(state, getters, rootState, rootGetters) {
      const url = rootGetters.getApiUrl;
      url.searchParams.set('c', state.api.components);

      return url.toString();
    },
    getLogo(state) {
      return state.settings.logo;
    },
    getYandexMetrika(state) {
      return state.settings.yandexMetrika;
    },
  },
  mutations: {
    setSettings(state, { item }) {
      state.settings = item;
    },
  },
  actions: {
    getSettings({ getters, commit }) {
      return new Promise((resolve) => {
        const url = new URL(getters.getApiUrl);
        url.searchParams.set('action', 'getSettingsInfo');

        axios
          .get(url.toString())
          .then((response) => {
            const item = response.data.data;
            commit('setSettings', { item });
            resolve(item);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
  },
};
